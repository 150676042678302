import AxiosService from './AxiosService';

export default class DashboardService {
    async getTicket() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.get('/dashboard/ticket');
        return res.data.data;
      } catch (error) {
        return error.response.status;
      }
    }

    async addMessage(param) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
          const res = await call.post('/dashboard/ticket/message', { 'message': param });
          return res.data;
        } catch (error) {
          return error.response.status;
        }
    }
    async closeTicket() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.post('/dashboard/ticket/close');
        return res.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async openTicket() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.post('/dashboard/ticket/new');
        return res.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async getAll() {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.get('/dashboard/ticket/all');
        return res.data;
      } catch (error) {
        return error.response.status;
      }
    }
    async openSelectedTicket(param) {
      const call =  new AxiosService().getAxiosInstanceJwt();
      try {
        const res = await call.post('/dashboard/ticket/openselectedticket', { 'ticket': param });
        return res.data.data;
      } catch (error) {
        return error.response.status;
      }
    }
}