import React, { useRef,useState, useEffect } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import NetworkService from '../service/NetworkService';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Network = () => {

    const navigate = useNavigate();
    const toast = useRef();
    const [selection, setSelection] = useState([]);
    const [data, setdata] = useState([{"expanded": true,
        "type": "person",
        "data": {
            "image": <i style={{fontSize:'3em',borderRight:'3px solid #FFB340',paddingRight:'5px',marginRight:'3px'}} className="fas fa-user"></i>,
            "name": "loading"
        }}]);

    const [loading, setLoading] = useState(true);
    const [member, setMember] = useState('');
    const [previousMember, setPreviousMember] = useState('');
    const [firstMember, setFirstMember] = useState('');
    const treeService = new NetworkService();
    const [inputValue, setInputValue] = useState('');
    const [nbrOfExpand, setNbrOfExpand] = useState(0);
    useEffect(() => {
        treeService.getInitTree().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setdata([data]);
            setPreviousMember(data.data.treeid);
            setFirstMember(data.data.treeid);
            setNbrOfExpand(nbrOfExpand + 1);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const nodeTemplate = (node) => {
        if (node.type === 'person') {
            return (
                <div className="flex flex-column">
                    <div className="flex flex-column align-items-center">
                        {node.data.is_gain_blocked == 1 ?(
                            node.data.treeid === "0" ?(
                                <>
                                    <div className="grid">
                                        <div style={{minWidth:'280px'}} className="col">
                                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                                <i style={{fontSize:'3em',borderRight:'3px solid #FFB340',paddingRight:'5px',marginRight:'3px'}} className="fas fa-user"></i>
                                                <div style={{textAlign:'left'}} className="overview-info">
                                                    <h6>Login : {node.data.name}</h6>
                                                    <h6>Total volume : $ {node.data.total_volume}</h6>
                                                    <h6>Total member : {node.data.total_member}</h6>
                                                    <h6>Pack bought : $ {node.data.total_pack}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ):
                            (
                                <>
                                    <div className="grid">
                                        <div style={{minWidth:'280px'}} className="col">
                                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                                <i style={{fontSize:'3em',borderRight:'3px solid #FFB340',paddingRight:'5px',marginRight:'3px'}} className="fas fa-user"></i>
                                                <div style={{textAlign:'left'}} className="overview-info">
                                                    <h6>Login : {node.data.name}</h6>
                                                    <h6>Total volume : $ {node.data.total_volume}</h6>
                                                    <h6>Total member : {node.data.total_member}</h6>
                                                    <h6>Pack bought : $ {node.data.total_pack}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            
                        ):(
                            <>
                                <div className="grid">
                                    <div style={{minWidth:'280px'}} className="col">
                                        <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                            <i style={{fontSize:'3em',borderRight:'3px solid #FFB340',paddingRight:'5px',marginRight:'3px'}} className="fas fa-user"></i>
                                            <div style={{textAlign:'left'}} className="overview-info">
                                                <h6>Login : {node.data.name}</h6>
                                                <h6>Total volume : $ {node.data.total_volume}</h6>
                                                <h6>Total member : {node.data.total_member}</h6>
                                                <h6>Pack bought : $ {node.data.total_pack}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                        }
                    </div>
                </div>
            );
        }

        return node.label;
    };

    const expand_node = (treeid) => {
        if(treeid === "0")
        {
            toast.current.show({ severity: 'error', summary: 'warning', detail: "Empty node", life: 5000 });
        }
        else
        {
            setLoading(true);
            if(nbrOfExpand == 1)
            {
                setPreviousMember(firstMember);
            }
            else
            {
                setPreviousMember(member);
            }
            treeService.getTree(treeid).then((res) => {
                if(res === 401)
                {
                    navigate('/expiration');
                }
                if(res.status.type === 'error')
                {
                    toast.current.show({ severity: 'error', summary: 'error', detail: res.status.message, life: 3000 });
                }
                else
                {
                    setdata([res.data.tree]);
                    setMember(treeid)
                    setNbrOfExpand(nbrOfExpand + 1);
                }
                setLoading(false);
            });
        }
    };

    return (
        <>
        <Toast ref={toast} />
        <div className="card overflow-x-auto">
                <div className='col-12 lg:col-12 xs:col-12'>
                    <Button label="Return" className="mr-2 mb-2" onClick={() => expand_node(previousMember)}/>
                    <Button label="My network" className="mr-2 mb-2" onClick={() => expand_node(firstMember)}/>
                </div>
            <OrganizationChart value={data} selectionMode="single" selection={selection} onNodeSelect={(e) => expand_node(e.node.data.treeid)} onSelectionChange={(e) => setSelection(e.data)} nodeTemplate={nodeTemplate} />
        </div>
        </>
    )
}

export default Network;
