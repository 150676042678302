import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
const navigate = useNavigate();
useEffect(() => {
    localStorage.removeItem('token');
}, [])


  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <button onClick={() => navigate('/')} className="logo p-link">
            <img src="assets/layout/images/logo_adsvantage.png" alt="adsvantage logo" />
            <span style={{color:'orange',fontSize:'2em',fontFamily:'arial',fontWeight:'bold'}}> Adsvantage</span>
          </button>
            <h3 style={{color:'orange',fontFamily:'arial',fontWeight:'bold'}}>Thank for your visit !</h3>
        </div>
        <div className="login-footer">
          <h5>Copyright Adsvantage</h5>
        </div>
      </div>
    </div>
  );
};
