import AxiosService from './AxiosService';

export default class SettingService {
    async gaIsActive() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/gauth/status');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async gaRemove(data) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/gauth/removega', {"code" : data});
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async gaCreate() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/gauth/new');
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async gaCheckCodeCreation(data) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/gauth/checkcodecreation', {"code" : data});
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getAddress() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/setting/withdrawaladdress');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async updateAddress(data) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/setting/withdrawaladdress', data);
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async sendPin() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/pin');
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async getSponsor() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/setting/sponsor');
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async roiComposed() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/setting/roicomposed');
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async networkComposed() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/setting/networkcomposed');
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async getComposed() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/setting/composed');
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
}