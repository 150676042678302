import React, { useState, useEffect,useRef } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import PackageLayout from './layout/PackageLayout';
import PackageService from '../service/PackageService';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import WalletService from '../service/WalletService';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Investing = () => {
    const navigate = useNavigate();
    const [adsPackages, setAdsPackages] = useState([]);
    const packageService = new PackageService();
    const toast = useRef();
    const [external_wallet, setExternal_wallet] = useState(0.00);
    const [internal_wallet, setInternal_wallet] = useState(0.00);
    const walletService = new WalletService();
    useEffect(() => {
        packageService.getPackage().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setAdsPackages(data.package);
        }).catch((error) =>{
            navigate('/expiration');
        });
        walletService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setExternal_wallet(data.external_live_amount);
            setInternal_wallet(data.internal_live_amount);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const buyPackage = (amount) => {
        packageService.buyPackage(amount).then((res) => {
            if(res.status.type === 'error')
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                walletService.getLiveWalletData().then((data) => {
                    if(data === 401)
                    {
                        navigate('/expiration');
                    }
                    setExternal_wallet(data.external_live_amount);
                    setInternal_wallet(data.internal_live_amount);
                });
            }
        });
    };
    return (
        
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
        <Toast ref={toast} />
        <div className="mb-3 font-bold text-3xl">
            <span className="text-900">Ads package </span>
            <span className="text-blue-600">Increase your daily earning</span>
        </div>
        <div className="text-900 mb-2">All our packages have <b>400% R.O.I</b></div>
        <div className="p-3 shadow-2 mb-3 inline-block surface-card mb-6" style={{ borderRadius: '10px' }}>
            <i className={"fas fa-wallet text-4xl text-primary"} ></i> <span className="text-900 text-xl mb-3 font-medium">Available amount {parseFloat(external_wallet) + parseFloat(internal_wallet)} $</span>
        </div>
        <div className="grid">
            {adsPackages && adsPackages.map((adsPackage, index) => (
                <div key={index} className="col-12 md:col-4 mb-4 px-5">
                <PackageLayout description={adsPackage.coefficient} amount={adsPackage.amount} icon={adsPackage.icon_font} buyPackage={buyPackage}/>
                </div>
            ))}
        </div>
    </div>
    
    );
};

export default Investing;
