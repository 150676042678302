import React, { useRef,useState, useEffect } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import DepositService from '../service/DepositService';
import { useNavigate } from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';
import { useInterval } from 'primereact/hooks';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Deposit = () => {
    const navigate = useNavigate();
    const toast = useRef();
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => confirmCancel()} className="p-button-text" autoFocus />
        </>
    );
    const [internal_wallet, setInternal_wallet] = useState(0.00);
    const [is_pending, setis_pending] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [amount_to_pay, setAmount_to_pay] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [seconde, setSeconds] = useState(30);
    const [depositData, setDepositData] = useState({
        "payment_id": null,
        "member_input_amount": 0.00,
        "pay_amount": 0.00,
        "pay_address": null,
        "pay_currency" : null,
        "payment_status": null});
    const depositService = new DepositService();
    useEffect(() => {
        depositService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setInternal_wallet(data.internal_live_amount);
        });
        depositService.getDepositPending().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data)
            {
                setDepositData(data);
                setis_pending(true);
                if(data.payment_status === "confirming" || data.payment_status === "confirmed" || data.payment_status === "finished" || data.payment_status === "partially_paid" || data.payment_status === "sending")
                {
                    setConfirming(true);
                }
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(
        () => {
            setSeconds((prevSecond) => (prevSecond === 1 ? 29 : prevSecond - 1)); //fn
            if(seconde === 1)
            {
                getPendingDeposit();
            }
        },
        1000,   //delay (ms)
        is_pending  //condition (when)
    );

    const confirmCancel = () => {
        setIsLoading(true);
        setDisplayConfirmation(false)
        depositService.cancelDeposit().then((response) => {
            if(response === 401)
            {
                navigate('/expiration');
            }
            if(response)
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.status.message, life: 3000 });
                setis_pending(false);
            }
        });
        setIsLoading(false);
    };

    const addDeposit = (amount_to_pay) => {
        setIsLoading(true);
        depositService.addDeposit(amount_to_pay).then((response) => {
            if(response === 401)
            {
                navigate('/expiration');
            }
            if(response)
            {
                toast.current.show({ severity: response.status.type, summary: response.status.type, detail: response.status.message, life: 3000 });
                getPendingDeposit();
            }
            setIsLoading(false);
        });
    };

    const getPendingDeposit = () => {
        depositService.getDepositPending().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data)
            {
                setDepositData(data);
                setis_pending(true);
                if(data.payment_status === "confirming" || data.payment_status === "confirmed" || data.payment_status === "partially_paid")
                {
                    setConfirming(true);
                    getWallet();
                }
                if(data.payment_status === "expired")
                {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Deposit expired, please cancel it", life: 3000 });
                }
            }
        });
    };

    const getWallet = () => {
        depositService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setInternal_wallet(data.internal_live_amount);
        });
    };

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 xs:col-12 md:col-12 lg:col-12 pb-0">
                    <div className="grid">
                        <Toast ref={toast} />
                        <div className="col-12 xs:col-12 md:col-6 lg:col-3">
                            <div className="px-2 pb-2">
                                <div className="px-3 xl:px-5 py-5 w-full border-round-2xl shadow-3" style={{ background: 'linear-gradient(to left top, #FFB340, #2a3241cc)' }}>
                                    <div className="mb-5">
                                        <img src={"assets/layout/images/logo_adsvantage.png"} className="w-2rem" alt="" />
                                    </div>
                                    <div className="mb-3">
                                        <h3 className="text-0" style={{ letterSpacing: '-0.5px' }}>
                                            Deposit wallet
                                        </h3>
                                        <div className="text-0 flex align-items-center justify-content-end">
                                            <span className="m-0 text-sm p-0 mr-2">
                                                Currency
                                            </span>
                                            <h4 className="m-0 text-0">{"USD"}</h4>
                                        </div>
                                        <h4 className="text-0">{internal_wallet} $</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!is_pending ? (
                            <>
                                <div className="col-12 xs:col-12 md:col-4 lg:col-3">
                                    <div className="card p-fluid">        
                                        <div className="field">
                                            <label htmlFor="amount_to_deposit">Amount to deposit (min 50):</label>
                                            <InputNumber id="amount_to_deposit" value={amount_to_pay} onValueChange={(e) => setAmount_to_pay(e.value)} min={50}/>
                                            <small>No fees from 100 usd</small>
                                        </div>
                                        <Button label="Make a deposit" className="p-button-primary" disabled={isLoading} onClick={() => addDeposit(amount_to_pay)}>
                                            {isLoading && <i className="pi pi-spin pi-spinner" />}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                            <div className='col-12 xs:col-12 md:col-4 lg:col-4'>
                            <div className="card p-fluid">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>{depositData.member_input_amount && depositData.member_input_amount < 100 ? parseFloat(depositData.member_input_amount) + 3 : depositData.member_input_amount} USDT (TRC20)</h6>
                                    </div>
                                    <b className="subtitle"><i className="fa-regular fa-clock fa-bounce" style={{ fontSize: '2rem' }}></i> <b>{seconde}</b></b>
                                </div>
                                <div className='flex justify-content-center'><QRCodeSVG value={depositData.pay_address} /></div>
                                <div className='text-xl text-center'>
                                    <br/>
                                    <h4 style={{wordWrap:'break-word'}}>{depositData.pay_address}</h4>
                                </div>
                                <p className=''></p>
                                {confirming ? (
                                    <><b>A payment has been detected, You can continue to navigate in the dashboard the paiment will be credited to your account when our system will validate it.</b></>
                                ) :
                                (
                                    <>
                                        <p>We are checking the blockchain every 30 secondes</p>
                                        <Button label="CANCEL MY DEPOSIT" className="p-button-primary" disabled={isLoading} onClick={() => setDisplayConfirmation(true)}>
                                        {isLoading && <i className="pi pi-spin pi-spinner" />}
                                        </Button>
                                    </>
                                )
                                }
                                <Dialog header="Confirmation" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                                    <div className="flex align-items-center justify-content-center">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                        <span>If you already sent money to this address, your funds will be lost.</span>
                                    </div>
                                </Dialog>
                            </div>
                            </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Deposit;