import React, { useRef, useState,useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import SettingService from '../service/SettingService';
import { useNavigate } from 'react-router-dom';
import {QRCodeSVG} from 'qrcode.react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ProfilService from '../service/ProfilService';

const Setting = () => {
    
    const navigate = useNavigate();
    const [sponsor, setSponsor] = useState(null);
    const [profil, setProfil] = useState(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayBasic2, setDisplayBasic2] = useState(false);
    const [isGAActive, setIsGAActive] = useState(false);
    const [gacode, setGacode] = useState('');
    const [url, setUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic(false)} icon="pi pi-check" className="p-button-secondary" />;
    const basicDialogFooter2 = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic2(false)} icon="pi pi-check" className="p-button-secondary" />;
    const [inputAddress, setInputAddress] = useState({
        "address": '',
        "gaAddress": '',
        "pinAddress": ''});
    const toast = useRef();
    const handleInputChange = (event) => {
      setGacode(event.target.value);
    };
    const settingService = new SettingService();
    const profilService = new ProfilService();

    useEffect(() => {
        profilService.getProfil().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setProfil(data);
        });
        settingService.gaIsActive().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data === 1)
            {
                setIsGAActive(true);
            }
            else
            {
                setIsGAActive(false);
            }
        });
        settingService.getAddress().then((data) => {
            setInputAddress({ ...inputAddress, "address": data });
        });
        settingService.getSponsor().then((data) => {
            setSponsor(data.data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRemoveGA = () => {
        settingService.gaRemove(gacode).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === 'success')
            {
                setDisplayBasic2(false);
                setIsGAActive(false);
            }
            toast.current.show({ severity: res.status.type, summary: res.status.type, detail: res.status.message, life: 3000 });
        });
    };
    const handleCreateGA = () => {
        settingService.gaCreate().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data.status.type === 'error')
            {
                toast.current.show({ severity: data.status.type, summary: data.status.type, detail: data.status.message, life: 3000 });
                
            }
            else
            {
                setUrl(data.data);
                setDisplayBasic(true);
            }
        });
    };
    const handleConfirmCodeGA = () => {
        settingService.gaCheckCodeCreation(gacode).then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data === true)
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Google auth activated', life: 3000 });
                setDisplayBasic(false);
                setIsGAActive(true);
            }
            else
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Wrong code', life: 3000 });
            }
        });
    };
    const handleChangeAddress = (e) => {
        setInputAddress({ ...inputAddress, [e.target.id]: e.target.value });
    };

    const handleSubmit = () => {
        settingService.updateAddress(inputAddress).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                // setInputAddress TODO clean the pin and ga
            }
        });
    };
    const handleChangeProfil = (e) => {
        setProfil({ ...profil, [e.target.id]: e.target.value });
    };

    const handleSubmitProfil = () => {
      setIsLoading(true);
      profilService.update(profil).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            toast.current.show({ severity: res.status.type, summary: res.status.type, detail: res.status.message, life: 3000 });
        });
      setIsLoading(false);
    };

    return (
        <div className='grid'>
            <div className='col-12 xs:col-12 md:col-6 lg:col-6'>
                <div className="grid">
                    <div className="col-12">
                    <Toast ref={toast} />
                        <div className="card p-fluid" style={{borderBottom:'5px solid #FFB340'}}>
                            <h5>My Personnal information</h5>
                            <div className="grid formgrid">
                                <div className="col-12 mb-2 lg:col-12 lg:mb-0">
                                    <div className="field">
                                        <label htmlFor="inputtext">Login</label>
                                        <InputText type="text" id="login" disabled value={profil?.login || ''} placeholder="Name" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Email</label>
                                        <InputText type="text" id="email" disabled value={profil?.email || ''} placeholder="Email" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Registration date</label>
                                        <InputText type="text" id="date" disabled value={profil?.ts_creation || ''} placeholder="Date" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Country</label>
                                        <InputText type="text" id="country" disabled value={profil?.country || ''} placeholder="Country" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Name</label>
                                        <InputText type="text" id="name" value={profil?.name || ''} placeholder="Name" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Firstname</label>
                                        <InputText type="text" id="firstname" value={profil?.firstname || ''} placeholder="Firstname" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Zipcode</label>
                                        <InputText type="text" id="zipcode" value={profil?.zipcode || ''} placeholder="Zipcode" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">City</label>
                                        <InputText type="text" id="city" value={profil?.city || ''} placeholder="City" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Street</label>
                                        <InputText type="text" id="street" value={profil?.street || ''} placeholder="Street" onChange={handleChangeProfil}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="inputtext">Number</label>
                                        <InputText type="text" id="number" value={profil?.number || ''} placeholder="Number" onChange={handleChangeProfil}/>
                                    </div>
                                </div>
                                <Button label="Update profil" className="ml-2 mr-2 mb-2" onClick={handleSubmitProfil}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 xs:col-12 md:col-6 lg:col-6'>
                <div className="grid">
                    <div className="col-12">
                            <Toast ref={toast} />
                            <Dialog header="Scan the qr code" visible={displayBasic} style={{ width: '70vw' }} modal onHide={() => setDisplayBasic(false)}>
                                <div className='flex justify-content-center'><QRCodeSVG value={url} /></div>
                                <p className='text-center mt-2'>Enter the code :</p>
                                <div className="mr-2 mb-4 text-center">
                                    <InputText value={gacode} onChange={handleInputChange}/>
                                </div>
                                <Button style={{width: "100%"}} label="Add Google auth" onClick={handleConfirmCodeGA}/>
                            </Dialog>
                            <Dialog header="Enter your code" visible={displayBasic2} style={{ width: '30vw' }} modal onHide={() => setDisplayBasic2(false)}>
                                <div className="mr-2 mb-2">
                                    <InputText value={gacode} onChange={handleInputChange}/>
                                </div>
                                <Button style={{width: "100%"}} label="Send" onClick={handleRemoveGA}/>
                            </Dialog>
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fa-brands fa-google"></i>
                                <div className="overview-info">
                                    <h5>Google auth</h5>
                                    {!isGAActive ? (
                                        <>
                                            <Button label="Activate" className="mr-2 mb-2"  onClick={handleCreateGA} />
                                        </>   
                                    ) : (
                                        <>
                                            <Button label="Desactivate" className="mr-2 mb-2"  onClick={() => setDisplayBasic2(true)} />
                                        </> 
                                    )}
                                </div>
                            </div>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <div style={{borderBottom:'5px solid #FFB340'}} className="card p-fluid">
                            <h5>My Withdrawal information</h5>
                            <div className="grid formgrid">
                                <div className="col-12 mb-2 lg:col-12 lg:mb-0">
                                    <div className="field">
                                        <label htmlFor="gaAddress">Withdrawal address</label>
                                        <InputText type="text" id="address" value={inputAddress.address} onChange={handleChangeAddress} placeholder="Please enter your usdt trc20 address"/>
                                    </div>
                                    {isGAActive ? (
                                        <>
                                            <div className="field">
                                                <label htmlFor="gaAddress">Google auth</label>
                                                <InputText type="text" id="gaAddress" value={inputAddress.ga} onChange={handleChangeAddress}/>
                                            </div>
                                        </>   
                                    ) : (
                                        <>
                                        </> 
                                    )}
                                </div>
                                <Button label="Change my withdrawal address" className="ml-2 mr-2 mb-2" onClick={handleSubmit}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setting;