import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import WalletService from '../service/WalletService';
import { useNavigate } from 'react-router-dom';
import SettingService from '../service/SettingService';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Withdrawal = () => {
    const navigate = useNavigate();
    const toast = useRef();
    const [displayConfirmationWithdrawal, setDisplayConfirmationWithdrawal] = useState(false);
    const [displayConfirmationTransfer, setDisplayConfirmationTransfer] = useState(false);
    const [isGAActive, setIsGAActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationDialogFooterWithdrawal = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationWithdrawal(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => sendWithdrawal(inputWithdrawal)} className="p-button-text" autoFocus />
        </>
    );
    const [external_wallet, setExternal_wallet] = useState(0.00);
    const [internal_wallet, setInternal_wallet] = useState(0.00);
    const [roi_wallet, setRoi_wallet] = useState(0.00);
    const [inputWithdrawal, setInputWithdrawal] = useState({
      amount_withdrawal: '',
      ga_withdrawal: '',
      pin_withdrawal: '',
      wichwallet:''
    });
    const [wichwallet, setWichwallet] = useState('commission');
    const walletService = new WalletService();
    const settingService = new SettingService();
    useEffect(() => {
        walletService.getDepositPending().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
        });
        walletService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setExternal_wallet(data.external_live_amount);
            setInternal_wallet(data.internal_live_amount);
            setRoi_wallet(data.roi_live_amount);
        });
        settingService.gaIsActive().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data === 1)
            {
                setIsGAActive(true);
            }
            else
            {
                setIsGAActive(false);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeWithdrawal = (e) => {
        setInputWithdrawal({ ...inputWithdrawal, [e.target.id]: e.target.value });
    };

    const sendPin = () =>{
        setIsLoading(true);
        walletService.sendPin().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            else
            {
                toast.current.show({ severity: res.status.type, summary: res.status.type, detail: res.status.message, life: 3000 });
            }
        }).catch(() => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: "An error has occured", life: 3000 });
        }).finally(() => {
            setIsLoading(false);
        });
    }
    const sendWithdrawal = (param) =>{
        param.wichwallet = wichwallet.code;
        setDisplayConfirmationWithdrawal(false)
        walletService.sendWithdrawal(param).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res && res.status && res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                walletService.getLiveWalletData().then((data) => {
                    if(data === 401)
                    {
                        navigate('/expiration');
                    }
                    setExternal_wallet(data.external_live_amount);
                    setInternal_wallet(data.internal_live_amount);
                    setRoi_wallet(data.roi_live_amount);
                });
            }
        });
    }

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 pb-0">
                <Toast ref={toast} />
                    <div className="grid">
                        <div className="col-12 xs:col-12 md:col-6 lg:col-3">
                            <div className="px-2 pb-2">
                                <div className="px-3 xl:px-5 py-5 w-full border-round-2xl shadow-3" style={{ background: 'linear-gradient(to left top, #FFB340, #2a3241cc)' }}>
                                    <div className="mb-5">
                                        <img src={"assets/layout/images/logo_adsvantage.png"} className="w-2rem" alt="" />
                                    </div>
                                    <div className="mb-3">
                                        <h3 className="text-0" style={{ letterSpacing: '-0.5px' }}>
                                            Withdrawal wallet
                                        </h3>
                                        <div className="text-0 flex align-items-center justify-content-end">
                                            <span className="m-0 text-sm p-0 mr-2">
                                                Currency
                                            </span>
                                            <h4 className="m-0 text-0">{"USD"}</h4>
                                        </div>
                                        <h4 className="text-0">{external_wallet} $</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 xs:col-12 md:col-6 lg:col-3">
                        <div className="card p-fluid">
                                <div className="field">
                                    <label htmlFor="amount_withdrawal">Amount to withdrawal (<b>Min 50 usd</b> - Fees 4%):</label>
                                    <InputText id="amount_withdrawal" type="number" min="30" step="1" value={inputWithdrawal.amount_withdrawal} onChange={handleChangeWithdrawal}/>
                                </div>
                                {isGAActive && isGAActive ? (
                                    <>
                                        <div className="field">
                                            <label htmlFor="ga_withdrawal">Google security (2FA):</label>
                                            <InputText id="ga_withdrawal" type="text" keyfilter="int" value={inputWithdrawal.ga_withdrawal} onChange={handleChangeWithdrawal}/>
                                        </div>
                                    </>   
                                ) : (
                                    <>
                                    </> 
                                )}
                                <Button label="Make a withdrawal" className="p-button-primary" onClick={() => setDisplayConfirmationWithdrawal(true)} />
                                <Dialog header="Confirmation" visible={displayConfirmationWithdrawal} onHide={() => setDisplayConfirmationWithdrawal(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterWithdrawal}>
                                    <div className="flex align-items-center justify-content-center">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                        <span>Are you sure ?</span>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Withdrawal;