import AxiosService from './AxiosService';

export default class DepositService {
    async getLiveWalletData() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/wallet/userdata');
            return res.data.data;   
        } catch (error) {
            return error.response.status;
        }
    }
    async getDepositPending() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/deposit/depositpending');
            return res.data.data;   
        } catch (error) {
            return error.response.status;
        }
    }
    async cancelDeposit() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/deposit/depositCancel');
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async addDeposit(amount) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/deposit/depositadd', { 'amount': amount });
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
}
