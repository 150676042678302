import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import SummaryService from '../service/SummaryService';
import { useNavigate } from 'react-router-dom';

const SumInvesting = () => {
    const navigate = useNavigate();
    const [packageBoughtData, setPackageBoughtData] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);

    const packageService = new SummaryService();

    useEffect(() => {
        setLoading(true);

        packageService.getPackageBought().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setPackageBoughtData(getlistFormat(data));
            setLoading(false);
        });

        initFilters();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getlistFormat = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };

    const initFilters = () => {
        setFilters({
            amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            roi: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>My investments</h5>
                    <DataTable
                        value={packageBoughtData}
                        paginator
                        className="p-datatable-gridlines"
                        showGridlines
                        rows={10}
                        dataKey="id"
                        filters={filters}
                        filterDisplay="menu"
                        loading={loading}
                        responsiveLayout="scroll"
                        emptyMessage="No Summary found."
                    >
                        <Column field="amount" header="Package" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="roi" header="Profit" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="status" header="Status" style={{ minWidth: '12rem' }} sortable/>
                        <Column header="Date" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} sortable/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default SumInvesting;
