import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const NotFound = (props) => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/');
    };

    return (
        <div className="exception-body notfound">
            <div className="exception-wrapper">
                <div className="exception-content">
                    <h1>404 not found</h1>
                    <span className="exception-subtitle">Looks like you are lost.</span>
                    <Button type="button" label="Go back to home" onClick={goDashboard}></Button>
                </div>
                <div className="exception-footer p-mt-4">
                    <h5>Copyright Adsvantage</h5>
                </div>
            </div>
        </div>
    );
};
