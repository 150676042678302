import React, { useRef, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import AxiosService from './../service/AxiosService';

export const Login = () => {
    const toast = useRef();
    const navigate = useNavigate();
    const url =  new AxiosService();
    const apiUrl = url.apiUrl;
    const [inputData, setInputData] = useState({
      login: '',
      password: '',
      ga: ''
    });
    const [inputDataForgot, setInputDataForgot] = useState({
        email: ''
      });
    
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingga, setIsLoadingga] = useState(false);
    useEffect(() => {
        localStorage.removeItem('token');
    }, [])
      
    const handleChange = (e) => {
        setInputData({ ...inputData, [e.target.id]: e.target.value });
    };

    const handleChangeForgot = (e) => {
        setInputDataForgot({ ...inputDataForgot, [e.target.id]: e.target.value });
    };
  
    const handleSubmit = () => {
        setIsLoading(true);
        if(inputData.login.length === 0 && inputData.password.length === 0)
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please fill the form', life: 3000 });
            setIsLoading(false);
        }
        else
        {
            axios.post(apiUrl + '/auth/login', inputData)
            .then((response) => {
            inputData.ga = "";
            if (response.data.status.type === "success")
            {
                const token = response.data.data.token;
                localStorage.setItem('token', token);
                navigate('/dashboard', { replace: true });
                setIsLoading(false);
            }
            else if(response.data.status.type === "ga")
            {
                setDisplayGa(true)
                setIsLoading(false);
            }
            else if(response.data.status.type === "maintenance")
            {
                navigate('/maintenance', { replace: true });
            }
            else
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.status.message, life: 3000 });
                setIsLoading(false);
            }
            })
            .catch((response) => {
                console.log(response);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
                setIsLoading(false);
            });
        }
    };

    const handleSubmitForgot = () => {
        setIsLoading(true);
        if(inputDataForgot.email.length === 0)
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please fill the form', life: 3000 });
            setIsLoading(false);
        }
        else
        {
            axios.post(apiUrl + '/auth/recovery', inputDataForgot)
            .then((response) => {
                toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.status.message, life: 3000 });
                setIsLoading(false);
            })
            .catch(() => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error has occured, please contact our support', life: 3000 });
                setIsLoading(false);
            });
        }
    };
    const [displayGa, setDisplayGa] = useState(false);
    const [displayForgot, setDisplayForgot] = useState(false);
    const dialogGaFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayGa(false)} icon="pi pi-check" className="p-button-secondary" />;
    const dialogForgotFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayForgot(false)} icon="pi pi-check" className="p-button-secondary" />;

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <Toast ref={toast} />
                    <button onClick={() => navigate('/')} className="logo p-link">
                        <img src="assets/layout/images/logo_adsvantage.png" alt="adsvantage logo" />
                        <span style={{color:'orange',fontSize:'2em',fontFamily:'arial',fontWeight:'bold'}}> Adsvantage</span>
                    </button>

                    <InputText id="login" placeholder="Login" value={inputData.login} onChange={handleChange}/>
                    <InputText id="password" type="password" placeholder="Password" value={inputData.password} onChange={handleChange}/>
                    <Button label="Sign in" onClick={() => handleSubmit(true)} disabled={isLoading} className='mt-2'>
                        {isLoading && <i className="pi pi-spin pi-spinner" />}
                    </Button>

                    <button style={{fontSize:'1.1em',fontFamily:'arial',fontWeight:'bold',marginTop:'10px'}} onClick={() => setDisplayForgot(true)} className="p-link forget-password">forgot password?</button>
                    <Dialog header="Google authenticator" visible={displayGa} modal onHide={() => setDisplayGa(false)}>
                        <div className='p-fluid'>
                            <InputText id="ga" type="number" keyfilter="int" value={inputData.ga} onChange={handleChange} placeholder="Google auth code"/>
                            <Button label="Submit" onClick={() => handleSubmit(true)} disabled={isLoadingga} className='mt-3'>
                                {isLoadingga && <i className="pi pi-spin pi-spinner" />}
                            </Button>
                        </div>
                        
                    </Dialog>
                    <Dialog header="Enter your email" visible={displayForgot} modal onHide={() => setDisplayForgot(false)}>
                        <div className='p-fluid'>
                            <InputText id="email" placeholder="Email" value={inputDataForgot.email} onChange={handleChangeForgot}/>
                            <Button label="Send" type="submit" disabled={isLoading} onClick={handleSubmitForgot} className='mt-3'>
                                {isLoading && <i className="pi pi-spin pi-spinner" />}
                            </Button>
                        </div>
                    </Dialog>
                </div>
                <div className="login-footer">
                    <h6>All right reserved to Adsvantage Ⓒ 2024</h6>
                </div>
            </div>
        </div>
    );
};
