import React, { useRef,useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import ContactService from '../service/ContactService';
import { ListBox } from 'primereact/listbox';

const Contact = () => {
    const navigate = useNavigate();
    const contactService = new ContactService();
    const [ticketContent, setTicketContent] = useState([]);
    const [ticketList, setTicketList] = useState(null);
    const [inputTicket, setInputTicket] = useState('');
    const [selectedTicket, setSelectedTicket] = useState(null);
    const toast = useRef();

    useEffect(() => {
        contactService.getTicket().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            setTicketContent(res);
        });
        contactService.getAll().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            setTicketList(res.data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChanged = (e) => {
        if (e.target.value.length <= 500) {
            setInputTicket(e.target.value);
        }
        else
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Maximum 500 characters', life: 3000 });
        }
    };

    const sendMessage = (param) =>{
        contactService.addMessage(param).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res && res.status && res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                contactService.getTicket().then((res2) => {
                    if(res === 401)
                    {
                        navigate('/expiration');
                    }
                    setTicketContent(res2)
                    setInputTicket('');
                });
            }
        });
    }
    const openTicket = (param) =>{
        contactService.openTicket(param).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res && res.status && res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                contactService.getTicket().then((res2) => {
                    if(res === 401)
                    {
                        navigate('/expiration');
                    }
                    setTicketContent(res2)
                });
                contactService.getAll().then((res) => {
                    if(res === 401)
                    {
                        navigate('/expiration');
                    }
                    setTicketList(res.data);
                });
            }
        });
    }
    const ticketTemplate = (option) => {
        console.log(option);
        return (
            <div>
                <div>Ticket : {option.ticket_id} <b>{(option.status === 1) ? "Closed" : "Open"}</b></div>
                <div>Date : {option.creation}</div>
            </div>
        );
    };
    const ticketSelected = (param) =>{   
        contactService.openSelectedTicket(param.ticket_id).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            setTicketContent(res);
        });
    }

    return (
        <div className="grid help-page">
            <Toast ref={toast} />
            <div className="col-12 xs:col-12 md:col-6 lg:col-2">
                <div className="card p-fluid" style={{borderBottom:'5px solid #FFB340'}} >
                    <h3>Ticket list</h3>
                    <ListBox value={selectedTicket} onChange={(e) => ticketSelected(e.target.value)} options={ticketList} optionLabel="ticket_id" itemTemplate={ticketTemplate} className="w-full md:w-14rem" />
                    <Button label="Create a ticket" onClick={() => openTicket()} className="p-button-primary mb-5" />
                </div>
            </div>
            <div className="col-12 xs:col-12 md:col-6 lg:col-10">
                
                    <div className="card articles-card" style={{ maxHeight: '500px', overflowY: 'auto',height:'100%',borderBottom:'5px solid #FFB340' }}>
                        <h3>Ticket content</h3>
                        <div className="blog-posts">
                            {ticketContent && ticketContent.map((training, index) => (
                                <div key={index} className={training.author === 'Adsvantage' ? 'blog-post' : 'blog-post text-right'}>
                                    <h6 className='mb-2'>{training.author}</h6>
                                    <div className='blog-text' style={{marginTop:"15px"}}>
                                        <span>
                                            {training.message}
                                        </span>
                                        <br/><br/>{training.ts_creation}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                
                {ticketContent && ticketContent.length > 0 && ticketContent[0].status === 0? (
                    
                    <div className="card p-fluid">
                    <div className="field">
                        <label htmlFor="amount_transfer"><b>Message</b></label>
                        <InputTextarea keyfilter={/^[^<>*!]+$/} value={inputTicket} onChange={handleChanged} rows={5} cols={30} />
                    </div>
                    <Button label="SEND" onClick={() => sendMessage(inputTicket.replace(/["',]/g, ' '))} className="p-button-primary" style={{borderRadius:'10px'}}/>
                    </div>
                ):
                    <></>
                }
            </div>
        </div>
    );
};

export default Contact;