import React, { useRef, useState,useEffect } from 'react';
import { Button } from 'primereact/button';
import SettingService from '../service/SettingService';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';


const AutoInvest = () => {
    const navigate = useNavigate();
    const [isRoiActive, setIsRoiActive] = useState(false);
    const [isNetworkActive, setIsNetworkActive] = useState(false);
    const settingService = new SettingService();
    const toast = useRef();
    const [displayConfirmationRoi, setDisplayConfirmationRoi] = useState(false);
    const [displayConfirmationNetwork, setDisplayConfirmationNetwork] = useState(false);
    const confirmationDialogFooterRoi = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationRoi(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => handleRoiComposed()} className="p-button-text" autoFocus />
        </>
    );
    const confirmationDialogFooterNetwork = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationNetwork(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => handleNetworkComposed()} className="p-button-text" autoFocus />
        </>
    );
    useEffect(() => {
        settingService.getComposed().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            else
            {
                setIsNetworkActive(data.data.is_network_composed);
                setIsRoiActive(data.data.is_roi_composed);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRoiComposed = () => {
        settingService.roiComposed().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data.status.type === 'error')
            {
                toast.current.show({ severity: data.status.type, summary: data.status.type, detail: data.status.message, life: 3000 });
            }
            else
            {
                setIsRoiActive(data.data);
            }
        });
        setDisplayConfirmationRoi(false);
    };
    const handleNetworkComposed = () => {
        settingService.networkComposed().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data.status.type === 'error')
            {
                toast.current.show({ severity: data.status.type, summary: data.status.type, detail: data.status.message, life: 3000 });
            }
            else
            {
                setIsNetworkActive(data.data);
            }
        });
        setDisplayConfirmationNetwork(false);
    };

    return (
        <div className="grid grid-nogutter surface-0 text-800">
            <div className="col-12 p-6 text-center md:text-left flex align-items-center ">
            <Toast ref={toast} />
                <section>
                    <span className="block text-6xl font-bold mb-1">Auto investing tool</span>
                    <div className="text-6xl text-primary font-bold mb-3">Transform all benefit from roi and networking to composed package and boost your daily profit</div>
                    <p className="mt-0 mb-4 text-700 line-height-3">You can choose wich financial source to activate</p>

                    {isRoiActive ? (
                        <>
                            <Button label="Desactivate roi composed pack" type="button" className="mr-3" style={{backgroundColor:"Green",border:'1px solid green'}} onClick={() => setDisplayConfirmationRoi(true)}/>
                        </>   
                    ) : (
                        <>
                            <Button label="Activate roi composed pack" type="button" className="mr-3" onClick={() => setDisplayConfirmationRoi(true)}/>
                        </> 
                    )}
                    {isNetworkActive ? (
                        <>
                            <Button label="Desactivate network composed pack" type="button" className="mr-3" style={{backgroundColor:"Green",border:'1px solid green'}} onClick={() => setDisplayConfirmationNetwork(true)}/>
                        </>   
                    ) : (
                        <>
                            <Button label="Activate network composed pack" type="button" className="mr-3" onClick={() => setDisplayConfirmationNetwork(true)}/>
                        </> 
                    )}
                </section>
                <Dialog header="Confirmation" visible={displayConfirmationRoi} onHide={() => setDisplayConfirmationRoi(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterRoi}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Are you sure ?</span>
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={displayConfirmationNetwork} onHide={() => setDisplayConfirmationNetwork(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterNetwork}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>Are you sure ?</span>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default AutoInvest;
