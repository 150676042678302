import AxiosService from './AxiosService';

export default class FaqService {
    async getFaqList() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/faq');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
}