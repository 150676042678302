import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

export const Error = (props) => {
    const navigate = useNavigate();

    return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <button onClick={() => navigate('/')} className="logo p-link">
            <img src="assets/layout/images/logo_adsvantage.png" alt="adsvantage logo" />
            <span style={{color:'orange',fontSize:'2em',fontFamily:'arial',fontWeight:'bold'}}> Adsvantage</span>
          </button>
            <h3>An error occured</h3>
        </div>
        <div className="login-footer">
          <h5>Copyright Adsvantage</h5>
        </div>
      </div>
    </div>
    );
};
