import React, { useState, useEffect } from 'react';
import NewsService from '../service/NewsService';
import { useNavigate } from 'react-router-dom';

const News = () => {
    
    const navigate = useNavigate();
    const [news, setnews] = useState([]);
    const newsService = new NewsService();

    useEffect(() => {
        newsService.getNewsList().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setnews(data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="grid">
            <div className="col">                
                {news && news.map((newList, index) => (
                    <div key={index} style={{borderLeft:'5px solid orange'}} className="card p-fluid">
                        <h5>{newList.title}</h5>
                        <div style={{borderTop:'1px dashed black',width:'100%',marginBottom:'10px'}}></div>
                        <div dangerouslySetInnerHTML={{ __html: newList.description }}></div>
                        <p style={{textAlign:'right'}}>{newList.ts_creation}</p>
                    </div>
                ))}
                    
            </div>
        </div>
    );
};

export default News;