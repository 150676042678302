import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const LayoutPackage = (props) => {
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => confirmBuy()} className="p-button-text" autoFocus />
        </>
    );
    const confirmBuy = () => {
        props.buyPackage(props.amount);
        setDisplayConfirmation(false);
    };
    return (
        <>
            <span className="p-3 shadow-2 mb-3 inline-block surface-card" style={{ borderRadius: '10px' }}>
                <i className={props.icon + " text-4xl text-primary"} ></i>
            </span>
            <div className="text-900 text-xl mb-3 font-medium">Package : $ {props.amount}</div>
            <Button style={{width:'35%'}}label="BUY" rounded size='large' onClick={() => setDisplayConfirmation(true)}></Button>
            <Dialog header="Confirmation" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>Are you sure ?</span>
                </div>
            </Dialog>
        </>
    );
};

export default LayoutPackage;