import React, { useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import AppMenu from './AppMenu';
import { useNavigate } from 'react-router-dom';

const AppTopbar = (props) => {
    const navigate = useNavigate();
    const searchPanelDesktop = useRef(null);
    const searchPanelMobile = useRef(null);

    useEffect(() => {
        if (props.searchActive) {
            if (window.innerWidth >= 576) searchPanelDesktop.current.focus();
            else searchPanelMobile.current.focus();
        }
    }, [props.searchActive]);

    const onInputKeydown = (event) => {
        const key = event.which;

        //escape, tab and enter
        if (key === 27 || key === 9 || key === 13) {
            if (props.searchActive) props.onTopbarSearchToggle();
        }
    };

    return (
        <div className="layout-topbar">
            <div className="layout-topbar-wrapper">
                <div className="layout-topbar-left">
                    <button tabIndex="0" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-bars"></i>
                    </button>
                    <button tabIndex="0" id="logo-link" onClick={() => navigate('/')} className="layout-topbar-logo p-link">
                        <img src="assets/layout/images/logo_adsvantage.png" alt="freya-layout" />
                    </button>
                </div>

                <AppMenu
                    menuMode={props.menuMode}
                    sidebarActive={props.sidebarActive}
                    sidebarStatic={props.sidebarStatic}
                    model={props.menu}
                    menuActive={props.menuActive}
                    onRootMenuItemClick={props.onRootMenuItemClick}
                    onMobileMenuActive={props.onMobileMenuActive}
                    onMenuItemClick={props.onMenuItemClick}
                    onSidebarMouseOver={props.onSidebarMouseOver}
                    onSidebarMouseLeave={props.onSidebarMouseLeave}
                    onToggleMenu={props.onToggleMenu}
                    resetActiveIndex={props.resetActiveIndex}
                    onMenuClick={props.onMenuClick}
                />

                <div className="layout-topbar-right">
                    <ul className="layout-topbar-actions">
                        <li className={classNames('topbar-item user-profile', { 'active-topmenuitem fadeInDown': props.topbarUserMenuActive })}>
                            <a className="p-link" style={{ cursor: 'pointer' }} target="_blank" href="https://t.me/adsvantageofficial">
                                <i className="pi pi-telegram" style={{ fontSize: '2rem' }}></i>
                            </a>
                        </li>
                        <li className={classNames('topbar-item user-profile', { 'active-topmenuitem fadeInDown': props.topbarUserMenuActive })}>
                            <button className="p-link" style={{ cursor: 'pointer' }} onClick={() => navigate('/setting')}>
                                <i className="pi pi-user" style={{ fontSize: '2rem' }}></i>
                            </button>
                        </li>
                    </ul>

                    {/* <button tabIndex="0" style={{ cursor: 'pointer' }} className="layout-rightpanel-button p-link" onClick={props.onRightPanelButtonClick}>
                        <i className="pi pi-arrow-left"></i>
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default AppTopbar;
