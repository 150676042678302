import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import WalletService from '../service/WalletService';
import { useNavigate } from 'react-router-dom';
import SettingService from '../service/SettingService';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Transfer = () => {
    const navigate = useNavigate();
    const toast = useRef();
    const [displayConfirmationTransfer, setDisplayConfirmationTransfer] = useState(false);
    const [isGAActive, setIsGAActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationDialogFooterTransfer = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmationTransfer(false)} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => sendTransfer(inputTransfer)} className="p-button-text" autoFocus />
        </>
    );
    const [external_wallet, setExternal_wallet] = useState(0.00);
    const [internal_wallet, setInternal_wallet] = useState(0.00);
    const [roi_wallet, setRoi_wallet] = useState(0.00);
    const [inputTransfer, setInputTransfer] = useState({
      amount_transfer: '',
      login_transfer: '',
      ga_transfer: '',
      pin_transfer: ''
    });
    const [dataTransfer, setDataTransfer] = useState({
        amount: '',
        login: ''
      });
    const walletService = new WalletService();
    const settingService = new SettingService();
    useEffect(() => {
        walletService.getDepositPending().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
        });
        walletService.getLiveWalletData().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setExternal_wallet(data.external_live_amount);
            setInternal_wallet(data.internal_live_amount);
        });
        settingService.gaIsActive().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            if(data === 1)
            {
                setIsGAActive(true);
            }
            else
            {
                setIsGAActive(false);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeTransfer = (e) => {
        setInputTransfer({ ...inputTransfer, [e.target.id]: e.target.value });

    };
    const sendTransfer = (param) =>{
        setDisplayConfirmationTransfer(false)
        walletService.sendTransfer(param).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Success', detail: res.status.message, life: 3000 });
                walletService.getLiveWalletData().then((data) => {
                    if(data === 401)
                    {
                        navigate('/expiration');
                    }
                    setExternal_wallet(data.external_live_amount);
                    setInternal_wallet(data.internal_live_amount);
                    setRoi_wallet(data.roi_live_amount);
                });
            }
        });
    }

    const getDataTransfer = () =>{
        walletService.dataTransfer(inputTransfer).then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "error")
            {
                toast.current.show({ severity: 'error', summary: 'Error', detail: res.status.message, life: 3000 });
            }
            else
            {
                setDataTransfer(res.data);
                setDisplayConfirmationTransfer(true)
            }
        });
    }

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 pb-0">
                <Toast ref={toast} />
                    <div className="grid">
                        <div className="col-12 xs:col-12 md:col-6 lg:col-3">
                            <div className="px-2 pb-2">
                                <div className="px-3 xl:px-5 py-5 w-full border-round-2xl shadow-3" style={{ background: 'linear-gradient(to left top, #FFB340, #2a3241cc)' }}>
                                    <div className="mb-5">
                                        <img src={"assets/layout/images/logo_adsvantage.png"} className="w-2rem" alt="" />
                                    </div>
                                    <div className="mb-3">
                                        <h3 className="text-0" style={{ letterSpacing: '-0.5px' }}>
                                            Transfer wallet
                                        </h3>
                                        <div className="text-0 flex align-items-center justify-content-end">
                                            <span className="m-0 text-sm p-0 mr-2">
                                                Currency
                                            </span>
                                            <h4 className="m-0 text-0">{"USD"}</h4>
                                        </div>
                                        <h4 className="text-0">{parseFloat(external_wallet) + parseFloat(internal_wallet)} $</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 xs:col-12 md:col-6 lg:col-3">
                            <div className="card p-fluid">
                                        <div className="field">
                                            <label htmlFor="amount_transfer">Amount to send to other member (min 1):</label>
                                            <InputText id="amount_transfer" type="number" min="1" step="1" keyfilter="int" value={inputTransfer.amount_transfer} onChange={handleChangeTransfer}/>
                                        </div>
                                        <div className="field">
                                            <label htmlFor="login_transfer">Member:</label>
                                            <InputText id="login_transfer" type="text" keyfilter="alphanum" value={inputTransfer.login_transfer} onChange={handleChangeTransfer}/>
                                        </div>
                                        <div className="grid formgrid">
                                            <div className="col-12 mb-2 lg:col-12 lg:mb-0">
                                            {isGAActive && isGAActive ? (
                                                <>
                                                    <div className="field">
                                                        <label htmlFor="ga_transfer">Google security (2FA):</label>
                                                        <InputText id="ga_transfer" type="text"  keyfilter="int" value={inputTransfer.ga_transfer} onChange={handleChangeTransfer}/>
                                                    </div>
                                                </>   
                                            ) : (
                                                <>
                                                </> 
                                            )}
                                            </div>
                                        </div>
                                        <Button label="Make a transfer" className="p-button-primary" onClick={() => getDataTransfer()} />
                                        <Dialog header="Confirmation" visible={displayConfirmationTransfer} onHide={() => setDisplayConfirmationTransfer(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterTransfer}>
                                            <div className="flex align-items-center justify-content-center">
                                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                                <span>Sending <b>{dataTransfer.amount && dataTransfer.amount} USD</b> to <b>{dataTransfer.login && dataTransfer.login}</b>.<br/>
                                                Are you sure you want to proceed to the transfer ?</span>
                                            </div>
                                        </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Transfer;