import AxiosService from './AxiosService';

export default class WalletService {
    async getInitTree() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/network/tree');
            return res.data.data.tree;
        } catch (error) {
            return error.response.status;
        }
    }
    async getTree(param) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        const data = {"treeid" : param};
        try {
            const res = await call.post('/dashboard/network/tree', data);
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getTreeLogin(param) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        const data = {"login" : param};
        try {
            const res = await call.post('/dashboard/network/tree', data);
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async changeDirection(param) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        const data = {"direction" : param};
        try {
            const res = await call.post('/dashboard/network/changedirection', data);
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async getAmount() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/network/amount');
            return res.data.data.amount;
        } catch (error) {
            return error.response.status;
        }
    }
}

