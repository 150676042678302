import AxiosService from './AxiosService';

export default class ProfilService {
    async getProfil() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/profil');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }
    }
    async update(data) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/profil/update', data);
            return res.data;
        } catch (error) {
            return error.response.status;
        }
    }
}
