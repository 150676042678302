import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import CareerService from '../service/CareerService';
import { useNavigate } from 'react-router-dom';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { Carousel } from 'primereact/carousel';
import { ProgressBar } from 'primereact/progressbar';
import DashboardService from '../service/DashboardService';

const Plan = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const [current, setCurrent] = useState('');
    const [percent, setPercent] = useState([]);
    const [currentPercent, setCurrentPercent] = useState('');
    const [career, setCareer] = useState([]);
    const careerService = new CareerService();
    const dashboardService = new DashboardService();

    useEffect(() => {
        careerService.getPlan().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setPlans(data.career);
            setCurrent(data.current_id);
        });
        dashboardService.getCareer().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setCareer(data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const itemTemplate = (plans) => {
        return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
                <div className="mb-3">
                    <h3>{plans.name}</h3>
                </div>
                <div>
                    <h6 className="mb-1">Bonus</h6>
                    <h5 className="mt-0 mb-3">{(plans.system_career_id > 1) ? plans.gift : '/'}</h5>
                    {(current >= plans.system_career_id) ? 
                    <Tag value="Reached" severity="warning"></Tag>
                    : <Tag value={'Not reached'} style = {{backgroundColor:'grey'}}></Tag>
                    }
                </div>
                <div className="col-12">
                    <b>Strong leg target ($ {career.demi_current_strong_branch && career.demi_current_strong_branch} / $ {plans.strong_branch && plans.strong_branch} )</b>
                </div>
                <div className="col-12">
                    <ProgressBar className="progressBar" value={plans.strong_branch && Math.round((career.demi_current_strong_branch/plans.strong_branch)*100)} showValue={false}></ProgressBar>
                </div>
                <div className="col-12">
                    <b>Other legs target ($ {career.current_sum_low_branch && career.current_sum_low_branch} / $ {plans.sum_low_branch && plans.sum_low_branch} )</b>
                </div>
                <div className="col-12">
                <ProgressBar className="progressBar" value={plans.sum_low_branch && Math.round((career.current_sum_low_branch/plans.sum_low_branch)*100)} showValue={false}></ProgressBar>
                </div>
            </div>
        );
    };
    
    return (
        <div className="card">
                <Carousel value={plans && plans} numScroll={1} numVisible={1} itemTemplate={itemTemplate} />
        </div>
    );
};

export default Plan;
