import React, { useRef,useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import DashboardService from '../service/DashboardService';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Dashboard = () => {
    const navigate = useNavigate();
    const toast = useRef();
    const [amount, setAmount] = useState({});
    const [login, setLogin] = useState({});
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(1);
    const [lastLogin, setlastLogin] = useState(null);
    const [lastTsNews, setlastTsNews] = useState(null);
    const [rate, setRate] = useState({});
    const [members, setMembers] = useState([]);
    const [news_list, setNews_list] = useState([]);
    const [career, setCareer] = useState([]);
    const [incentive, setIncentive] = useState([]);
    const [careerName, setCareerName] = useState('');
    const [careerGift, setCareerGift] = useState(0);
    const [visibleCareer, setVisibleCareer] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [differenceInDays, setDifferenceInDays] = useState(null);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setVisibleCareer(false)} icon="pi pi-check" className="p-button-secondary" />;
    const basicDialogFooterNews = <Button type="button" label="Open the page" onClick={() => navigate('/news')} icon="pi pi-check" className="p-button-primary" />;
    const dashboardService = new DashboardService();
    const registerLink = `https://app.adsvantage.io/register/${login}`;
    const [copySuccess, setCopySuccess] = useState(false);

    useEffect(() => {
        dashboardService.getDepositPending().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
        });
        dashboardService.getAmount().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setAmount(data);
            setLogin(data.login);
            const totalInvest = parseFloat(data.package_amount_all) + parseFloat(data.pack_amount);
            const totalIncome = parseFloat(data.total_income);
            if(totalInvest > 1)
            {
                const x4 = Math.round(totalInvest * 4);
                const Pie1 = x4 - totalIncome;
                if(totalIncome < x4)
                {
                    setTo(Pie1);
                    setFrom(totalIncome);
                }
            }
        });
        dashboardService.getNews().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setNews_list(data);
            if(data.last_login.length && data.last_login.length > 1)
            {
                setlastLogin(data.last_login[1].ts);
                setlastTsNews(data.news[0].ts);
            }
        });
        dashboardService.getCareer().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setCareer(data);
        });
        dashboardService.checkCareerReach().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "success")
            {
                setCareerName(res.data.name);
                setCareerGift(res.data.gift);
                setVisibleCareer(true);
            }
        });
        dashboardService.checkIncentive().then((res) => {
            if(res === 401)
            {
                navigate('/expiration');
            }
            if(res.status.type === "success")
            {
                setIncentive(res.data);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const pieOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(registerLink)
          .then(() => {
            setCopySuccess(true)
            toast.current.show({ severity: "success", summary: "Success", detail: "Link copied", life: 3000 });
          })
          .catch(() => setCopySuccess(false));
    };

    const getChart = () => {
        return {
                labels: ['Total earn', 'x4'],
                datasets: [
                    {
                        data: [from,to],
                        backgroundColor: ['#FFB340', 'rgba(41, 50, 65, 0.8)']
                    }
                ]
        };
    };
    const chartData = getChart();

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12">
                    <Toast ref={toast} />
                    <div className="notification">
                        <h6>

                        Hello everyone,<br/>

We hope you are doing well. A little over three weeks ago, our CEO, Mohombi, and his associate encountered a serious problem. They had invested their private funds in a DeFi system that was unfortunately hacked. Regarding the Vantage venture, the funds of our CEO and his associate had been used as leverage to buy in large quantities and benefit from preferential prices for the acquisition of advertising space. The same applied to Melo Ads, where their funds were supposed to stabilize the token's value and support the launch of the application. This situation has resulted in a significant financial loss for them<br/>.

For the past three weeks, the entire ads Vantage team has been striving to find a solution to compensate for this colossal loss, but unfortunately, no viable alternative has been found. Therefore, we have made the decision to suspend all our activities and partially refund all members who have made a deposit in our company. Starting tomorrow evening at 10 PM, we will activate withdrawals for those who request them. You have one month to submit your request, and you will be refunded within 24 hours.<br/>

We are working tirelessly to refund you the full amount of your deposits, but we cannot yet guarantee if and when that will be possible. For now, we assure you that we can refund 50% of your deposits starting tomorrow. We will also calculate the withdrawals already made and pay you the difference. We are sincerely sorry for this situation. Please know that we have done everything possible to limit the damage. Stay connected, and we will keep you informed of any new information through this group.<br/>

Thank you for your understanding.<br/>
                            
                        </h6>
                    </div>
                </div>
                <div className="col-12">
                    <div className="notification">
                        <h6>
                            {amount.package_amount_all > 0 ? 
                            (
                                <>
                                    You can share this link for subscriptions in your network :{' '}https://app.adsvantage.io/register/{amount.login}
                                    <button className="p-link" onClick={handleCopyLink}>
                                        <i  style={{fontSize:'1.5em'}} className="fas fa-copy"></i>
                                    </button>
                                </>
                            ):
                                <>
                                    To activate your referal link, you have to invest
                                </>
                            }
                            {amount.is_gain_blocked == 1 ? 
                            (
                                <>
                                    <p style={{color:'red'}}>Your account is not actif, you have to invest</p>
                                </>
                            ):
                                <></>
                            }
                            
                        </h6>
                    </div>
                </div>
                {/* <div className="col-12">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card stocks">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6 style={{color:'#FFB340'}}>Turkey incentive ! 24 March - 24 April</h6>
                                        <p className="subtitle">Your directs have to invest at least $ 1000.00 and your network have to make at least $ 5000.00 in your weakest legs. <span style={{color:"#FFB340"}} className="p-link" onClick={()=> navigate('/news')}>Lear more</span></p>
                                    </div>
                                </div>
                                <div className="progress">
                                    <div className="grid">
                                        <div className="col-12 md:col-12 xl:col-2">
                                            <b>Direct target ($ {incentive.to1000Direct && incentive.to1000Direct} / $ {1000} )</b>
                                        </div>
                                        <div className="col-12 md:col-12 xl:col-10">
                                            <ProgressBar className="progressBar" value={incentive.to1000Direct && Math.round((incentive.to1000Direct/1000)*100)} showValue={false}></ProgressBar>
                                        </div>
                                        <div className="col-12 md:col-12 xl:col-2">
                                            <b>Weakest legs target ($ {incentive.to5000Weak && incentive.to5000Weak} / $ {5000} )</b>
                                        </div>
                                        <div className="col-12 md:col-12 xl:col-10">
                                        <ProgressBar className="progressBar" value={incentive.to5000Weak && Math.round((incentive.to5000Weak/5000)*100)} showValue={false}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="col-12 md:col-12 xl:col-6 pb-0">
                    <div className="grid">
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-wallet"></i>
                                <div className="overview-info">
                                    <h5>My wallet</h5>
                                    <h4>$ {parseFloat(amount.total_wallet)}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-sack-dollar"></i>
                                <div className="overview-info">
                                    <h5>Total invest</h5>
                                    <h4>$ {parseFloat(amount.package_amount_all) + parseFloat(amount.pack_amount)}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-user-tie"></i>
                                <div className="overview-info">
                                    <h5>Your rank</h5>
                                    <h4>{amount.name && amount.name}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-cubes-stacked"></i>
                                <div className="overview-info">
                                    <h5>My network</h5>
                                    <h4>{amount.tree_member && amount.tree_member}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-globe"></i>
                                <div className="overview-info">
                                    <h5>Total volume</h5>
                                    <h4>$ {amount.tree_volume && amount.tree_volume}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="far fa-user"></i>
                                <div className="overview-info">
                                    <h5>Direct members</h5>
                                    <h4>{amount.total_direct && amount.total_direct}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-dollar-sign"></i>
                                <div className="overview-info">
                                    <h5>Network inc</h5>
                                    <h4>$ {amount.total_network_income && amount.total_network_income}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-hand-holding-dollar"></i>
                                <div className="overview-info">
                                    <h5>Ads inc</h5>
                                    <h4>$ {amount.package_income && amount.package_income}</h4>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col">
                            <div style={{borderBottom:'5px solid #FFB340'}} className="card overview-box white">
                                <i style={{fontSize:'5em'}} className="fas fa-chart-line"></i>
                                <div className="overview-info">
                                    <h5>Total R.O.I</h5>
                                    <h4>{amount.total_rate && amount.total_rate} %</h4>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-12 xl:col-6">
                    <div className="grid">
                        <div className="col-12 md:col-6 xl:col-6">
                            <div style={{height:'485px'}} className="card chart">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Earning limit</h6>
                                        <p className="subtitle">Pie chart</p>
                                    </div>
                                </div>
                                <Chart type="pie" data={chartData} options={pieOptions} style={{ width: '100%' }} />
                            </div>
                        </div>
                        <div className="col-12 md:col-6 xl:col-6">
                            <div className="card stocks">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>R.O.I distribution</h6>
                                        <p className="subtitle">{amount.rate_date && amount.rate_date}</p>
                                    </div>
                                </div>
                                <ul>
                                    <li>
                                        <div className="stock-name">
                                            <h6>Rate</h6>
                                        </div>
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-up"></i>
                                            <h6>{amount.rate && amount.rate}%</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="stock-name">
                                            <h6>$ 1.000</h6>
                                        </div>
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-up"></i>
                                            <h6>{amount.rate && (parseFloat(amount.rate)*1.05).toFixed(2)}%</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="stock-name">
                                            <h6>$ 2.500</h6>
                                        </div>
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-up"></i>
                                            <h6>{amount.rate && (parseFloat(amount.rate)*1.10).toFixed(2)}%</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="stock-name">
                                            <h6>$ 5.000</h6>
                                        </div>
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-up"></i>
                                            <h6>{amount.rate && (parseFloat(amount.rate)*1.15).toFixed(2)}%</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="stock-name">
                                            <h6>$ 10.000</h6>
                                        </div>
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-up"></i>
                                            <h6>{amount.rate && (parseFloat(amount.rate)*1.20).toFixed(2)}%</h6>
                                        </div>
                                    </li>
                                </ul>
                                <Button type="button" label="Go summary roi page" onClick={()=> navigate('/sumroi')}></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card stocks">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Next unilevel</h6>
                                        <p className="subtitle">{career.next_target_volume_percent && career.next_target_volume_percent} %</p>
                                    </div>
                                </div>
                                <div className="progress">
                                    <div className="grid">
                                        <div className="col-12 md:col-12 xl:col-2">
                                            <b>Volume target ($ {career.total_current_volume && career.total_current_volume} / $ {career.next_target_volume && career.next_target_volume} )</b>
                                        </div>
                                        <div className="col-12 md:col-12 xl:col-10">
                                            <ProgressBar className="progressBar" value={career.next_target_volume && Math.round((career.total_current_volume/career.next_target_volume)*100)} showValue={false}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Next career</h6>
                                        <p className="subtitle">{career.next_career_name && career.next_career_name}</p>
                                    </div>
                                </div>
                                <div className="progress">
                                    <div className="grid">
                                        <div className="col-12 md:col-12 xl:col-2">
                                            <b>Strong leg target ($ {career.demi_current_strong_branch && career.demi_current_strong_branch} / $ {career.next_career_strong_branch && career.next_career_strong_branch} )</b>
                                        </div>
                                        <div className="col-12 md:col-12 xl:col-10">
                                            <ProgressBar className="progressBar" value={career.next_career_strong_branch && Math.round((career.demi_current_strong_branch/career.next_career_strong_branch)*100)} showValue={false}></ProgressBar>
                                        </div>
                                        <div className="col-12 md:col-12 xl:col-2">
                                            <b>Other legs target ($ {career.current_sum_low_branch && career.current_sum_low_branch} / $ {career.next_career_sum_low_branch && career.next_career_sum_low_branch} )</b>
                                        </div>
                                        <div className="col-12 md:col-12 xl:col-10">
                                        <ProgressBar className="progressBar" value={career.next_career_sum_low_branch && Math.round((career.current_sum_low_branch/career.next_career_sum_low_branch)*100)} showValue={false}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
