import AxiosService from './AxiosService';

export default class WalletService {
    async getLiveWalletData() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/wallet/userdata');
            return res.data.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async sendPin() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/pin');
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async sendWithdrawal(data) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/wallet/withdrawal/new', data);
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async sendTransfer(data) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/wallet/transfer/new', data);
            return res.data;
        } catch (error) {
            return error.response.status;
        }   
    }
    async getDepositPending() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.get('/dashboard/deposit/depositpending');
            return res.data.data;   
        } catch (error) {
            return error.response.status;
        }
    }

    async dataTransfer(data) {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
            const res = await call.post('/dashboard/wallet/transfer/datatransfer',data);
            return res.data;   
        } catch (error) {
            return error.response.status;
        }
    }
}
