import AxiosService from './AxiosService';

export default class CareerService {
    async getPlan() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
          const res = await call.get('/dashboard/career/plan');
          return res.data.data;
        } catch (error) {
          return error.response.status;
        }
      }
      async getPercentPlan() {
        const call =  new AxiosService().getAxiosInstanceJwt();
        try {
          const res = await call.get('/dashboard/career/percent');
          return res.data.data;
        } catch (error) {
          return error.response.status;
        }
      }
}