import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import SummaryService from '../service/SummaryService';
import { useNavigate } from 'react-router-dom';

const SumResidual = () => {
    const navigate = useNavigate();
    const [residualData, setResidualData] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);

    const directService = new SummaryService();

    useEffect(() => {
        setLoading(true);

        directService.getResidualSummary().then((data) => {
            if(data === 401)
            {
                navigate('/expiration');
            }
            setResidualData(getlistFormat(data));
            setLoading(false);
        });

        initFilters();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getlistFormat = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);
            return d;
        });
    };

    const initFilters = () => {
        setFilters({
            amount_lv1: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv2: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv3: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv4: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv5: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv6: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv7: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv8: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv9: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            amount_lv10: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Residual summary</h5>
                    <DataTable
                        value={residualData}
                        paginator
                        className="p-datatable-gridlines"
                        showGridlines
                        rows={10}
                        dataKey="id"
                        loading={loading}
                        responsiveLayout="scroll"
                        emptyMessage="No Summary found."
                    >
                        <Column field="amount_lv1" header="Level 1" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv2" header="Level 2" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv3" header="Level 3" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv4" header="Level 4" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv5" header="Level 5" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv6" header="Level 6" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv7" header="Level 7" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv8" header="Level 8" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv9" header="Level 9" style={{ minWidth: '12rem' }} sortable/>
                        <Column field="amount_lv10" header="Level 10" style={{ minWidth: '12rem' }} sortable/>
                        <Column header="Date" dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} sortable/>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default SumResidual;
